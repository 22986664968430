<template>
  <div class="layout-padding row justify-center" style="max-width: 1980px;">
    <div class="q-pa-xl full-width">
      <div class="row">
        <div class="text-h4 col-md-9">Imported Modules</div>
      </div>

      <!-- Add some controls like All, Imported, Not Imported. Will also need to add a schedule report, email report, and download -->
      <div class="row full-width justify-end q-mb-sm">
        <div v-if="$store.getters['auth/company'].name === 'Mid-States'">
          <filter-select :options="customerList" v-bind:selection.sync="filterBy" label="Choose Customer"/>
        </div>
      </div>
      <div class="row q-gutter-lg justify-end">
        <!-- <q-btn label="Generate Report" @click="getReportData" color="primary"/> -->
        <q-btn label="Schedule Report" @click="scheduleReport" outline color="primary" />
        <q-btn flat label="Email Report" icon="mail" @click="emailReport" color="primary" />
        <q-btn flat label="Download as PDF" icon="get_app" @click="getPDF" color="primary" />
      </div>

      <div class="row q-pa-lg full-width">
        <div class="col-12">
          <vue-highcharts :key="componentKey" :highcharts="highcharts" :options="options" ref="chart"></vue-highcharts>
        </div>
      </div>

      <div class="row full-width">
        <div class="col-12 q-pa-md">
          <q-table title="Global Modules" :data="data" :columns="columns" :pagination.sync="paginationControl" row-key="uid" class="bg-lmsBackground primaryText-text">
            <template v-slot:top-right>
              <q-btn flat label="Download as CSV" icon="get_app" @click="getCSV" color="primary" />
            </template>
          </q-table>
        </div>
      </div>
      <canvas id="canvas" style="display: none;"></canvas>
      <canvas id="myCanvas" style="display: none;"></canvas>

      <q-dialog v-model="showRecipientsModal">
        <AddRecipientsAndSend v-if="report" type="email" :propReport="report" @closeForm="showRecipientsModal = false"/>
      </q-dialog>

      <q-dialog v-model="scheduledReportModal">
        <ScheduledReportForm v-if="report" :propReport="report" @closeForm="closeForm"/>
      </q-dialog>
    </div>
  </div>
</template>

<script>
function wrapCsvValue (val, formatFn) {
  let formatted = formatFn !== void 0
    ? formatFn(val)
    : val

  formatted = formatted === void 0 || formatted === null
    ? ''
    : String(formatted)

  formatted = formatted.split('"').join('""')
  /**
   * Excel accepts \n and \r in strings, but some other CSV parsers do not
   * Uncomment the next two lines to escape new lines
   */
  // .split('\n').join('\\n')
  // .split('\r').join('\\r')

  return `"${formatted}"`
}

import VueHighcharts from 'vue2-highcharts'
import Highcharts from 'highcharts'
import FilterSelect from 'components/FilterSelect'
import { date, exportFile } from 'quasar'
import ScheduledReportForm from 'components/ScheduledReportForm'
import AddRecipientsAndSend from 'components/ManageReportSubscribers'

require('highcharts/modules/exporting')(Highcharts)

export default {
  name: 'ImportedModulesReport',
  components: { VueHighcharts, FilterSelect, ScheduledReportForm, AddRecipientsAndSend },
  data () {
    return {
      value: 0,
      tab: 'imported',
      highcharts: Highcharts,
      componentKey: 0,
      customers: [],
      filterBy: null,
      report: {},
      showRecipientsModal: false,
      scheduledReportModal: false,
      options: {
        title: {
          text: 'Imported Modules',
          align: 'center',
          verticalAlign: 'middle'
        }
      },
      paginationControl: { rowsPerPage: 30, page: 1 },
      data: [],
      columns: [
        {
          name: 'moduleTitle',
          label: 'Title',
          align: 'left',
          field: row => row.title,
          sortable: true,
          showFor: '*'
        },
        {
          name: 'moduleDescription',
          label: 'Description',
          align: 'left',
          field: row => {
            if (row.description.length > 50) {
              return row.description.substring(0, 50) + '...'
            }
            return row.description
          },
          sortable: true,
          showFor: '*'
        },
        {
          name: 'member',
          label: 'Member',
          align: 'left',
          field: row => row.member,
          sortable: true,
          showFor: '*'
        },
        {
          name: 'category',
          label: 'Category',
          align: 'left',
          field: row => row.category,
          sortable: true,
          showFor: '*'
        },
        {
          name: 'moduleCreatedAt',
          label: 'Created At',
          align: 'left',
          field: row => date.formatDate(row.created_at, 'MM/DD/YYYY'),
          sortable: true,
          showFor: '*'
        },
        {
          name: 'importStatus',
          label: 'Status',
          align: 'left',
          field: row => row.importStatus,
          sortable: true
        }
      ]
    }
  },
  computed: {
    customerList: function () {
      return this.customers.map(customer => {
        return { label: customer.name, value: customer.uid }
      })
    }
  },
  watch: {
    filterBy (newVal) {
      if (newVal) {
        this.fetchData(newVal)
      }
    }
  },
  methods: {
    getCSV () {
      // naive encoding to csv format
      const content = [ this.columns.map(col => wrapCsvValue(col.label)) ].concat(
        this.data.map(row => this.columns.map(col => wrapCsvValue(
          typeof col.field === 'function'
            ? col.field(row)
            : row[col.field === void 0 ? col.name : col.field],
          col.format
        )).join(','))
      ).join('\r\n')

      const status = exportFile(
        `importedModulesReport__${date.formatDate(Date.now(), 'YYYY-MM-DD')}.csv`,
        content,
        'text/csv'
      )

      if (status !== true) {
        this.$q.notify({
          message: 'Browser denied file download...',
          color: 'negative',
          icon: 'warning'
        })
      }
    },
    async getPDF () {
      this.$q.loading.show()

      let res = await this.$axios.get(`${this.$consts.DOWNLOAD_REPORT_URL}?type=importedModules&chartType=pie&startDate=2020-01-27&endDate=&options[groupBy]=&options[filterBy]=${this.filterBy || this.$store.getters['auth/company'].uid}&options[isComplete]=`, { responseType: 'arraybuffer' })
      let pdfBlob = new Blob([res], { type: 'application/pdf' })
      let link = document.createElement('a')
      link.href = URL.createObjectURL(pdfBlob)
      link.download = `importedModulesReport_${date.formatDate(Date.now(), 'YYYY/MM/DD')}.pdf`
      link.click()

      this.$q.loading.hide()
    },
    async fetchData (companyUid) {
      this.$loading(true, 'Fetching Data')
      let res = await this.$axios.get(`${this.$consts.GET_REPORT}/importedModules?options[filterBy]=${companyUid}`)
      let highcharts = res
      if (highcharts.plotOptions) {
        highcharts.plotOptions['pie'].animation = true // turning animation on for selected chart type
      }

      if (highcharts.series) {
        this.componentKey = highcharts.series.length > 1 ? this.chartType.key : 1000 // rendering a new chart if it's a different chart type to get the animation.
      }
      this.$loading(false)
      this.options = highcharts
      this.data = res.raw
    },
    emailReport () {
      this.report = {
        isNew: true,
        user_uid: this.$store.state.auth.user.uid,
        type: 'importedModules',
        settings: {
          chartType: 'pie',
          options: {
            groupBy: null,
            filterBy: this.filterBy || this.$store.getters['auth/company'].uid,
            isComplete: null
          },
          user_uids: []
        },
        startDate: date.formatDate(new Date(), 'YYYY/MM/DD'),
        endDate: date.formatDate(new Date(), 'YYYY/MM/DD')
      }
      this.showRecipientsModal = true
    },
    scheduleReport () {
      this.report = {
        type: 'importedModules',
        label: '',
        recurring_date: null,
        recurring_duration: null,
        settings: {
          chartType: 'pie',
          duration: '0',
          description: null,
          options: {
            groupBy: null,
            filterBy: this.filterBy || this.$store.getters['auth/company'].uid,
            isComplete: null
          },
          user_uids: []
        },
        is_active: true,
        isNew: true
      }

      this.scheduledReportModal = true
    },
    closeForm (report) {
      this.scheduledReportModal = false
    }
  },
  async created () {
    this.fetchData(this.$store.getters['auth/company'].uid)

    this.$store.dispatch('auth/getGlobalLocations').then(res => {
      this.customers = res
    })
  }
}
</script>

<style lang="stylus" scoped>
  .vue-highcharts
    overflow unset !important
</style>
