import { render, staticRenderFns } from "./importedModulesReport.vue?vue&type=template&id=99bfb2d2&scoped=true&"
import script from "./importedModulesReport.vue?vue&type=script&lang=js&"
export * from "./importedModulesReport.vue?vue&type=script&lang=js&"
import style0 from "./importedModulesReport.vue?vue&type=style&index=0&id=99bfb2d2&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99bfb2d2",
  null
  
)

export default component.exports